import React from "react";
import "./App.css";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

const App = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const getImageSize = () => {
    if (isMobile) {
      return "100%";
    } else if (isTablet) {
      return "50%";
    } else {
      return "30%";
    }
  };

  const doodleSize = () => {
    if (isMobile) {
      return "25%";
    } else if (isTablet) {
      return "20%";
    } else {
      return "15%";
    }
  };

  return (
    <div className="App">
      <div class="header">
        <a href="#" class="nav-item">
          <img src="https://i.imgur.com/glszNgW.png" alt="Home" />
        </a>
        <a href="#" class="nav-item">
          <img src="https://i.imgur.com/fC0hyFU.png" alt="About" />
        </a>
        <a href="#" class="nav-item">
          <img src="https://i.imgur.com/nrRxvxs.png" alt="Contact" />
        </a>
        <a href="#" class="nav-item">
          <img src="https://i.imgur.com/gjzCrvK.png" alt="Projects" />
        </a>
      </div>
      <img src="https://i.imgur.com/bU7Xy7A.png" alt="tik tak toe" style={{
          position: "absolute",
          top: "25%",
          left: "13%",
          transform: "translate(-50%, -50%)",
          width: doodleSize(),
          height: "auto",
        }} />
        <img src="https://i.imgur.com/kTfMwDp.png" alt="shapes" style={{
          position: "absolute",
          top: "80%",
          left: "19%",
          transform: "translate(-50%, -50%)",
          width: doodleSize(),
          height: "auto",
        }} />
        <img src="https://i.imgur.com/isfOYak.png" alt="sCool" style={{
          position: "absolute",
          top: "70%",
          left: "82%",
          transform: "translate(-50%, -50%)",
          width: doodleSize(),
          height: "auto",
        }} />
        <img src="https://i.imgur.com/RQT9IiN.png" alt="sCool" style={{
          position: "absolute",
          top: "24%",
          left: "82%",
          transform: "translate(-50%, -50%)",
          width: doodleSize(),
          height: "auto",
        }} />
      <img
        src="https://i.imgur.com/8Zn6CLj.png"
        alt="Coming Soon :)"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: getImageSize(),
          height: "auto",
        }}
      />
      <div className="footer">
        <a
          href="https://www.linkedin.com/in/luke-stewart-4a428590/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} className="icon" />
        </a>
        <a
          href="https://github.com/moeshy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faGithub} className="icon" />
        </a>
      </div>
    </div>
  );
};

export default App;
